















































































































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import LabelStatus from '../../project-detail/components/label-status.vue'
import { MintingViewModel } from '../viewmodels/minting-viewmodel'

@Observer
@Component({
  components: {
    'label-status': LabelStatus,
    'custom-progress-bar': () => import('./custom-progress-bar.vue'),
  },
})
export default class PhaseNftInfoCard extends Vue {
  @Inject() providers
  walletStore = walletStore
  @Inject({}) vm!: MintingViewModel
  tab = 1
  destroyed() {
    //
  }
}
